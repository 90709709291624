<template>
  <div class="app-container v">
    <!--工具栏-->
    <div class="head-container">
      <!-- 搜索 -->
      <el-input v-model="query.keyword" clearable placeholder="输入分类搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>

      <!-- 新增 -->
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      <!-- <div style="display: inline-block;">
        <el-button class="filter-item" size="mini" type="warning" icon="el-icon-more" @click="changeExpand">{{ expand ? '折叠' : '展开' }}</el-button>
        <eForm ref="form" :is-add="true" :dicts="dicts" />
      </div>-->
    </div>
    <!--表单组件-->
    <eForm ref="form" :is-add="isAdd" @submit="handleFormSubmit" v-if="data" />
    <!--表格渲染-->
    <div class="flex scroll-able scroll-container-main fs-mini" v-loading="loading">
      <el-tree ref="tree" class="border" :data="data" node-key="id" highlight-current :props="{isLeaf: 'isLeaf'}" accordion>
        <div class="flex h c" slot-scope="{data}">
          <div class="flex">{{data.name}}</div>
          <div class="text-nw fc-g" :style="{width: infoWidth + 'px'}">{{data.info}}</div>
          <div class="row-commands" v-if="user.entId===data.entId">
            <el-button size="mini" type="text" @click.stop="edit(data)">编辑</el-button>
            <el-button :disabled="data.children && data.children.length>0" class="danger" type="text" size="mini" @click.stop="subDelete(data)">删除</el-button>
          </div>
        </div>
      </el-tree>
    </div>
    <!-- <el-table ref="tree" :data="data" lazy row-key="id" :load="lazyLoad" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="name" label="分类名称" min-width="240" />
      <el-table-column prop="info" label="分类描述" min-width="240" />
      <el-table-column width="130px" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" :disabled="scope.row.children && scope.row.children.length>0" type="danger" icon="el-icon-delete" size="mini" />
          </el-popover>
        </template>
      </el-table-column>
    </el-table>-->
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/category";
import eForm from "./form";
import { mapGetters } from "vuex";

export default {
  name: "Category",
  components: { eForm },
  mixins: [initData],
  data() {
    let iw = Math.round(document.body.offsetWidth * 0.4);
    if (iw < 240) iw = 240;
    return {
      current: null,
      delLoading: false,
      infoWidth: iw,
      query: {
        keyword: null,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    checkPermission,
    beforeInit(node) {
      this.url = "api/category/tree";
      if (this.query.keyword) {
        this.params["name"] = this.query.keyword;
      } else {
        delete this.params.name;
        // this.url = "api/categoryByPid";
        // if (node && node.data) {
        //   this.params["pid"] = node.data.id;
        // } else {
        //   this.params["pid"] = "";
        // }
      }
      return true;
    },
    afterLoad(res) {
      // if (res && res.length) {
      //   res.forEach(o => {
      //     if (o.isLeaf === false) {
      //       o.hasChildren = true;
      //     }
      //   });
      // }
    },
    findTreeData(source, id) {
      let d = null;
      if (source && source.length) {
        for (let c of source) {
          if (c.id === id) {
            d = c;
          } else {
            d = this.findTreeData(c.children, id);
          }
          if (d) break;
        }
      }
      return d;
    },
    handleFormSubmit(isAdd, d) {
      let tree = this.$refs.tree,
        needAppend = false;
      if (isAdd) {
        needAppend = true;
      } else if (this.current) {
        if (d.pid === this.current.pid) {
          Object.assign(this.current, d);
        } else {
          tree.remove(this.current);
          needAppend = true;
        }
      }
      if (needAppend) {
        if (d.pid) {
          let node = tree.getNode(d.pid);
          if (node) {
            tree.append(d, node);
            if (node.isLeaf) {
              node.isLeafByUser = false;
              node.updateLeafState();
            }
          }
        } else {
          tree.append(d, tree.root);
        }
      }
    },
    subDelete(row) {
      this.$confirm("您确定要删除该分类吗？", "操作确认", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then((_) => {
        del(row.id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
          this.$refs.tree && this.$refs.tree.remove(row);
        });
      });
    },
    add() {
      this.current = null;
      this.isAdd = true;
      this.$refs.form.resetForm();
    },
    edit(data) {
      this.current = data;
      this.isAdd = false;
      let cd = JSON.parse(JSON.stringify(data));
      delete cd.children;
      this.$refs.form.resetForm(cd);
    },
  },
};
</script>
