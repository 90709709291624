<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增分类' : '编辑分类'" @closed="form=null" width="480px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px" v-if="form">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="form.name" maxlength="20" />
      </el-form-item>
      <el-form-item v-if="form.pid !== 0" label="上级分类">
        <tree-picker ref="pTree" v-model="form.pid" url="api/category/tree" :exclude-keys="isAdd ? null : [form.id]" accordion placeholder="选择上级分类，不选择则为顶级分类" style="width:100%" clearable />
      </el-form-item>
      <!-- <el-form-item label="排序号" prop="displayNo">
        <el-input-number v-model="form.displayNo" :min="0" :max="999" :step="1" :precision="0" controls-position="right" style="width:100%" />
      </el-form-item> -->
      <el-form-item label="销售限制" style="width: 280px;">
        <el-switch v-model="form.purchaseLimit" size="mini" />
      </el-form-item>
      <el-form-item label="描述" prop="info">
        <el-input v-model="form.info" type="textarea" resize="none" :rows="5" :maxlength="100" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/category";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      depts: [],
      form: null,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }]
      }
    };
  },
  methods: {
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.doAdd();
          } else {
            this.doEdit();
          }
        }
      });
    },
    getData() {
      let d = JSON.parse(JSON.stringify(this.form));
      let ps = {
        values: [],
        labels: []
      };
      if (d.pid) {
        ps = this.$refs.pTree.getFullInfo(d.pid);
      }
      ps.values.push(d.id || "_self");
      ps.labels.push(d.name);
      d.fullPath = "," + ps.values.join(",") + ",";
      d.fullName = ps.labels.join("/");
      return d;
    },
    doAdd() {
      let d = this.getData();
      this.loading = true;
      add(d)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$emit("submit", true, res);
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      let d = this.getData();
      this.loading = true;
      edit(d)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$emit("submit", false, res);
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    resetForm(form) {
      if (form) {
        this.form = JSON.parse(JSON.stringify(form));
      } else {
        this.form = {
          id: null,
          name: "",
          pid: null,
          enabled: "true",
          displayNo: "",
          description: ""
        };
      }
      this.dialog = true;
    }
  }
};
</script>